export const MEDTHOD = {
  GET: "get",
  POST: "post",
  PATCH: "patch",
  DELETE: "delete",
  PUT: "put",
};

export const ROLES = {
  ADMIN: "admin",
  USER: "user",
};
